<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'List Pengajuan'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci disini"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-none d-lg-block text-none"
          @click="$router.push('/input-loan')"
        >
          Tambah Pengajuan
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-lg-none d-md-block text-none"
          @click="$router.push('/input-loan')"
        >
          Tambah
        </v-btn>
      </div>

      <v-data-table
        class="cursor-pointer"
        :headers="headers"
        :items="submissions"
        :items-per-page="10"
        :search="searchQuery"
        @click:row="toDetail"
      ></v-data-table>

      <v-btn
        color="primary"
        rounded
        :disabled="isAllLoaded"
        class="ml-4 white--text text-none"
        @click="loadMore"
      >
        Load More Data
      </v-btn>

      <v-btn
        color="primary"
        rounded
        :disabled="isAllLoaded"
        class="ml-4 white--text text-none"
        @click="loadAll"
      >
        Load All Data
      </v-btn>
    </v-card>
    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      loading: false,
      isAllLoaded: false,
      headers: [
        {
          text: "Nama Pinjaman",
          align: "start",
          sortable: true,
          value: "nama_pinjaman",
        },
        {
          text: "Nama Penggunaan Pinjaman",
          align: "start",
          sortable: true,
          value: "nama_penggunaan_pinjaman",
        },
        { text: "Status Pengajuan", value: "nama_status_pengajuan_pinjaman" },
        {
          text: "Nilai Pengajuan",
          value: "nilai_pengajuan_pinjaman_formatted",
        },
        { text: "Nilai Plafond", value: "nilai_plafond_formatted" },
        { text: "Suku Bunga", value: "suku_bunga_pinjaman" },
        {
          text: "Tanggal Mulai Pendanaan",
          value: "tgl_mulai_pendanaan_formatted",
        },
      ],
      submissions: [],
      submissionsPagination: {
        firstPage: 1,
        lastPage: 100,
      },
    };
  },

  methods: {
    toDetail(item) {
      this.$router.push("/edit-loan/" + item.id);
    },
    async getSubmission() {
      this.loading = true;

      await store
        .dispatch("office/fetchListSubmission", this.submissionsPagination)
        .then((r) => {
          if (r) {
            this.loading = false;
          }
        });
      this.submissions = store.state.office.listSubmission;
      this.submissions.forEach((a) => {
        a.nilai_pengajuan_pinjaman_formatted = converters.currency(
          a.nilai_pengajuan_pinjaman
        );
        a.nilai_plafond_formatted = converters.currency(a.nilai_plafond);
        a.tgl_mulai_pendanaan_formatted = converters.date(
          a.tgl_mulai_pendanaan
        );
      });
    },
    loadMore() {
      if (this.submissionsPagination.lastPage <= 40000) {
        this.submissionsPagination.lastPage += 1000;
        this.getSubmission();
      } else {
        this.isAllLoaded = true;
      }
    },
    loadAll() {
      if (this.submissionsPagination.lastPage <= 40000) {
        this.submissionsPagination.lastPage += 40000;
        this.getSubmission();
        this.isAllLoaded = true;
      }
    },
  },

  mounted() {
    this.getSubmission();
  },
};
</script>
